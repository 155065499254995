@import "styles/variables.scss";

.first-slider {
  position: relative;
  display: flex;
  max-width: 1920px;
  margin: 0 auto;

  &__img {
    @media (max-width: $tablet) {
      width: 100%;
      height: 536px;
      &, img {
        object-fit: cover;
      }
    }

    @media (min-width: $desktop) {
      width: 100%;
      height: 654px;
      &, img {
        object-fit: cover;
      }
    }
  }

  &__wrap {
    @media (max-width: $tablet) {
      position: absolute;
      top: 16px;
    }

    @media (min-width: $desktop) {
      position: absolute;
      top: 136px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__button-swap {
    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__info {
    @media (max-width: $tablet) {
      padding: 32px 24px 24px;
      background: $yellow;
      width: 342px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      margin: 0 auto;
    }

    @media (min-width: $desktop) {
      padding: 32px 28px 32px 32px;
      background: $yellow;
      width: fit-content;
      display: flex;
      flex-direction: column;
    }

    &::before {
      display: block;
      content:'';
      width: 171px;
      height: 8px;
      margin-bottom: 9px;
      background: $black;
    }
  }

  &__info-text-wrap {
    @media (max-width: $tablet) {
      display: flex;
      margin-bottom: 24px;
    }

    @media (min-width: $desktop) {
      display: flex;
    }
  }

  &__info-title {
    @media (max-width: $tablet) {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.4000000059604645px;
      text-align: left;
    }

    @media (min-width: $desktop) {
      font-size: 46px;
      font-style: normal;
      font-weight: 700;
      line-height: 55px;
      letter-spacing: -1.2000000476837158px;
      text-align: left;
      margin-bottom: 10px;
    }
  }

  &__info-heading {
    display: flex;
    flex-direction: column;
  }

  &__info-price {
    @media (max-width: $tablet) {
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -1.2000000476837158px;
      line-height: 62px;
    }
    @media (min-width: $desktop) {
      font-size: 120px;
      font-style: normal;
      font-weight: 700;
      line-height: 100px;
      letter-spacing: -1.2000000476837158px;
      margin-bottom: 5px;
    }
  }

  &__info-text {
    @media (max-width: $tablet) {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.4000000059604645px;
      margin-bottom: 16px;
    }

    @media (min-width: $desktop) {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: -0.8999999761581421px;
    }
  }

  &__info-icons-wrap {
    @media (max-width: $tablet) {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    @media (min-width: $desktop) {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
    }

    & ul {
      @media (max-width: $tablet) {
        display: flex;
        margin-bottom: 16px;
      }

      @media (min-width: $desktop) {
        display: flex;
        margin-right: 37px;
      }

      & li {
        display: flex;
        align-items: center;
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }

        & svg {
          @media (max-width: $tablet) {
            width: 32px;
            height: 32px;
            margin-right: 8px;
          }

          @media (min-width: $desktop) {
            width: 24px;
            height: 24px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  &__info-icons-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-right: 16px;
  }

  &__info-icons-text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
