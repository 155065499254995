@import "styles/variables.scss";

.second-slider {
  position: relative;
  display: flex;
  max-width: 1920px;
  margin: 0 auto;

  &__img {
    @media (max-width: $tablet) {
      display: flex;
      height: 536px;
    }

    @media (min-width: $desktop) {
      width: 100%;
      height: 654px;
      &, img {
        object-fit: cover;
      }
    }
  }

  &::after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: $blackFont;
    opacity: 0.9;
    z-index: 1;
  }

  &__wrap {
    @media (max-width: $tablet) {
      position: absolute;
      top: 48px;
      left: 40px;
      z-index: 2;
      max-width: fit-content;
    }

    @media (min-width: $desktop) {
      position: absolute;
      top: 88px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }
  }

  &__info {
    width: fit-content;
    display: flex;
    flex-direction: column;

    &::before {
      display: block;
      content: "";
      height: 8px;
      background: $white;
      margin-bottom: 8px;

      @media (max-width: $tablet) {
        width: 132px;
      }

      @media (min-width: $desktop) {
        width: 171px;
      }
    }
  }

  &__info-heading {
    display: flex;
    flex-direction: column;
  }

  &__info-title {
    color: $yellow;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 5px;

    @media (max-width: $tablet) {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.4000000059604645px;
    }

    @media (min-width: $desktop) {
      font-size: 46px;
      line-height: 64px;
      letter-spacing: -1.2000000476837158px;
    }
  }

  &__info-price {
    color: $yellow;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -1.2000000476837158px;
    margin-bottom: 10px;
    font-size: 60px;
    line-height: 55px;

    @media (min-width: $tablet-small) {
      font-size: 100px;
      line-height: 110px;
    }

    @media (min-width: $desktop) {
      font-size: 180px;
      font-weight: 700;
      line-height: 200px;
    }

    @media (min-width: $desktop-huge) {
      font-size: 240px;
      font-weight: 700;
      line-height: 250px;
    }
  }

  &__info-text {
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.4000000059604645px;
    color: $white;
    margin-bottom: 16px;

    @media (max-width: $tablet) {
      line-height: 24px;
      font-size: 18px;
    }

    @media (min-width: $desktop) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__info-icons-wrap {
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;

    @media (max-width: $tablet) {
      flex-direction: column;
    }

    @media (min-width: $desktop) {
      align-items: center;
    }

    & ul {
      display: flex;

      @media (max-width: $tablet) {
        margin-bottom: 16px;
      }

      @media (min-width: $desktop) {
        margin-right: 37px;
      }

      & li {
        display: flex;
        align-items: center;
        margin-right: 8px;
        color: $white;

        &:last-child {
          margin-right: 0;
        }

        & svg {
          @media (max-width: $tablet) {
            width: 32px;
            height: 32px;
            margin-right: 8px;
          }

          @media (min-width: $desktop) {
            width: 24px;
            height: 24px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  &__info-icons-text-wrap {
    display: flex;
  }

  &__info-icons-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-right: 16px;
    color: $white;
  }

  &__info-icons-text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $white;
  }
}
