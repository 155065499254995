@import "styles/variables.scss";

.fifth-slider {
  position: relative;
  display: flex;
  max-width: 1920px;
  margin: 0 auto;

  &__img {
    width: 100%;
    &, img {
      object-fit: cover;
      object-position: right;
    }

    @media (max-width: $tablet) {
      height: 536px;
    }

    @media (min-width: $desktop) {
      height: 654px;
    }
  }

  &__wrap {
    height: 654px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;

    @media (max-width: $tablet) {
      height: 536px;
    }

    &::before {
      display: block;
      content: "";
      width: 50%;
      height: 654px;
      background: $yellow;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      padding-bottom: 19px;

      @media (max-width: $tablet) {
        height: 50%;
        width: 100%;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    height: 100%;
    padding-top: 104px;
    padding-bottom: 19px;
    box-sizing: border-box;

    @media (max-width: $tablet) {
      height: 536px;
      padding: 20px;
      padding-right: 0;
    }
  }

  &__info-wrap {
    @media (min-width: $tablet) {
      width: 50%;
    }
  }

  &__title {
    max-width: 421px;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -1.2px;
    display: flex;
    flex-direction: column;
    margin-bottom: 51px;

    @media (max-width: $tablet) {
      font-size: 26px;
      line-height: 26px;
      margin-bottom: 21px;
    }

    &::before {
      display: block;
      content: "";
      width: 200px;
      height: 8px;
      background: $black;
      margin-bottom: 8px;

      @media (max-width: $tablet) {
        width: 100px;
        margin-left: 0;
      }
    }
  }

  &__text {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.9px;
    margin-bottom: 13px;
  }

  &__price {
    font-size: 120px;
    font-style: normal;
    font-weight: 700;
    line-height: 120px;
    letter-spacing: -1.2px;
    margin-bottom: 24px;

    @media (max-width: $tablet) {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 15px;
    }
  }

  & ul {
    display: flex;
    margin-bottom: 24px;

    & li {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.4px;
      display: flex;

      &:not(:last-child) {
        margin-right: 24px;

        &::after {
          display: block;
          content: "";
          width: 1px;
          height: 24px;
          margin-left: 24px;
          background: $blackFont;

          @media (max-width: $tablet) {
            height: 100%;
            margin-left: 10px;
          }
        }
      }
    }
  }

  &__bottom-text {
    font-size: 12px;
    display: flex;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    max-width: 408px;
    margin-top: auto;

    &::before {
      display: block;
      content: "*";
      margin-right: 10px;
    }
  }
}
